import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

import { ContactService } from '../../contact/contact.service';
import { Router } from '@angular/router';
import Splitting from "splitting";
import ScrollOut from "scroll-out";
import { Particles } from 'particlesjs';
import { Meta, Title } from '@angular/platform-browser';


interface WordPair {
  word: string;
  imageSrc: string;
  alt: string;
  additionalText: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],

})
export class HomeComponent implements OnInit {

  public Currentno: any;
  public Totalno: any;
  public CurrentProduct: any;
  public FooterComponentObj: any;
  element: HTMLElement;
  public dpi = window.devicePixelRatio || 1;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  @ViewChild('cursor', { static: true }) cursorElement: ElementRef;
  @ViewChild('box', { static: true }) boxElement: ElementRef;
  @ViewChild('container', { static: true }) container!: ElementRef;
  @ViewChild('tsquareContainer', { static: true }) tsquareContainer: ElementRef;
  @ViewChild('text', { static: true }) textElement: ElementRef
  public context: CanvasRenderingContext2D;
  public particle_count = 30;
  public particles = [];
  public couleurs = ["#c00000", "#fdc830", "#f37335", "#ffbd39"];
  wordPairs: WordPair[] = [
    { word: 'Smartent', imageSrc: 'assets/img/smartentlogo.png', alt: 'smartent', additionalText: 'Production Planning : Optimized' },
    { word: 'T-square', imageSrc: 'assets/img/tsquarelogo.png', alt: 'tsquare', additionalText: 'Engineering drawings : Analyzed' }
    // Add more word pairs as needed
  ];
  myStyle: object = {};
  myParams: object = {};
  width: number = 90;
  height: number = 90;
  words: string[] = ['lectus', 'T-Square', 'SmartEnt', 'lectus', 'Zoom'];
  currentIndex: number = 0;
  constructor(public contactService: ContactService, private title: Title, private meta: Meta, private router: Router, private renderer: Renderer2) {

  }

  ngOnInit() {


    this.title.setTitle('Home');
    this.myStyle = {
      'position': 'fixed',
      'width': '100%',
      'height': '100%',
      'z-index': -1,
      'top': 0,
      'left': 0,
      'right': 0,
      'bottom': 0,
    };

    this.myParams = {
      "particles": {
        "number": {
          "value": 750,
          "density": {
            "enable": true,
            "value_area": 2583.107274969174
          }
        },
        "color": {
          "value": "#c00000"
        },
        "shape": {
          "type": "circle",
          "stroke": {
            "width": 0,
            "color": "#ffffff"
          },
          "polygon": {
            "nb_sides": 6
          },
          "image": {
            "src": "assets/img/image/kynealogo.png",
            "width": 100,
            "height": 100
          },

        },
        "opacity": {
          "value": 0.7536014029813354,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 5,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 40,
            "size_min": 0.1,
            "sync": false
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 100,
          "color": "#000000",
          "opacity": 1,
          "width": 1.5
        },
        "move": {
          "enable": true,
          "speed": 6,
          "direction": "none",
          "random": true,
          "straight": false,
          "out_mode": "bounce",
          "bounce": false,
          "attract": {
            "enable": false,
            "rotateX": 4730,
            "rotateY": 1200
          }
        }
      },

      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": true,
            "mode": "grab"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 100,
            "line_linked": {
              "opacity": 1
            }
          },
          "bubble": {
            "distance": 400,
            "size": 40,
            "duration": 2,
            "opacity": 8,
            "speed": 3
          },
          "repulse": {
            "distance": 200,
            "duration": 0.4
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 2
          }
        }
      },
      "retina_detect": true
    };


    this.Currentno = 1;
    this.Totalno = 3;
    this.CurrentProduct = this.products[0];

    Splitting();
    ScrollOut({
      targets: '[data-splitting]',
      scrollingElement: ".scrollsection"

    });
    ScrollOut({
      onShown: function (el) {
        // use the web animation API
        el.classList.remove('animated');
        void el.offsetWidth;
        el.classList.add('animated');

      },
      onHidden: function (el) {
        // hide the element initially
        el.classList.remove('animated');

      }
    });


    this.animatePairs()
  }

  ngAfterViewInit(): void {

  }

  options: AnimationOptions = {
    path: 'https://assets10.lottiefiles.com/packages/lf20_egwdrB.json',
  };

  animationCreated(animationItem: AnimationItem): void {

  }



  public products = [{
    title: 'PRODUCT <span>HEADER</span> TITLE',
    description: 'Hungry to invent the future?',
    image: 'assets/img/placeholder.webp',
  },
  {
    title: 'PRODUCT <span>HEADER</span> TITLE1',
    description: 'Create powerful customer journey maps based on real customer interactions and reimagine the digital  journey',
    image: 'assets/img/placeholder.webp',
  },
  {
    title: 'PRODUCT <span>HEADER</span> TITLE2',
    description: 'Provide cognitive responses to customers and suppliers by understanding  the content and context of conversations',
    image: 'assets/img/placeholder.webp',
  },
  {
    title: 'PRODUCT <span>HEADER</span> TITLE2',
    description: 'Understand documents and identify areas of interest',
    image: 'assets/img/placeholder.webp',
  },
  {
    title: 'PRODUCT <span>HEADER</span> TITLE2',
    description: 'Seamlessly integrate decisions from structured and unstructured data analysis and drive actions',
    image: 'assets/img/placeholder.webp',
  }
  ]



  config: SwiperOptions = {
    loop: false,
    autoplay: false,

    autoHeight: true,
    allowTouchMove: true,

    navigation: {
      nextEl: '.feedbacks-swiper-button-next',
      prevEl: '.feedbacks-swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 40
      },

      1200: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 1,
      }
    },

  };

  config1: SwiperOptions = {
    loop: false,
    autoplay: false,

    autoHeight: true,
    allowTouchMove: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 40
      },
      768: {
        slidesPerView: 2,
      },

      1200: {
        slidesPerView: 5,
      },
      1024: {
        slidesPerView: 5,
      }
    },

  };

  config2: SwiperOptions = {
    loop: false,
    autoplay: false,

    autoHeight: true,
    allowTouchMove: true,

    navigation: {
      nextEl: '.product-swiper-button-next',
      prevEl: '.product-swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 40
      },
      768: {
        slidesPerView: 1,
      },

      1200: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 1,
      }
    },
    on: {
      slideChange: (activeIndex) => {
        this.CurrentProduct = this.products[activeIndex.realIndex];

      },
      slideChangeTransitionEnd: () => {
        console.log('slideChange Event');
      }
    }
  };

  config3: SwiperOptions = {

    fadeEffect: {
      crossFade: true
    },
    virtualTranslate: true,
    parallax: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    speed: 1500,
    slidesPerView: 1,
    effect: "fade",

    on: {
      slideChange: (activeIndex) => {

      },
      realIndexChange: (activeIndex) => {

        this.Currentno = activeIndex.realIndex + 1
        this.Totalno = activeIndex.slides.length;


      }
    }

  };

  config4: SwiperOptions = {

    fadeEffect: {
      crossFade: true
    },
    virtualTranslate: true,
    parallax: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    speed: 1500,
    slidesPerView: 1,
    effect: "fade",

    on: {
      slideChange: (activeIndex) => {

      },
      slideChangeTransitionEnd: (activeIndex) => {

      }
    }

  };
  animatePairs() {
    const animatedWordContainers = document.querySelectorAll('.fadeanimated-word-container');
    const pairCount = animatedWordContainers.length;

    const fadeInDuration = 800; 
    const fadeOutDuration = 1000; 
    const delayBetweenPairs = 800; 

    const animatePair = (index: number) => {
        const currentPair = animatedWordContainers[index] as HTMLElement;

        let opacity = 0;
        const fadeInInterval = setInterval(() => {
            opacity += 0.01; 
            currentPair.style.opacity = opacity.toString();
            if (opacity >= 1) {
                clearInterval(fadeInInterval);
                setTimeout(() => {
                    const fadeOutInterval = setInterval(() => {
                        opacity -= 0.01; 
                        currentPair.style.opacity = opacity.toString();
                        if (opacity <= 0) {
                            clearInterval(fadeOutInterval); 
                            index = (index + 1) % pairCount; 
                            setTimeout(() => {
                                animatePair(index); 
                            }, delayBetweenPairs);
                        }
                    }, fadeOutDuration / 100); 
                }, delayBetweenPairs);
            }
        }, fadeInDuration / 100); 
    };

    animatedWordContainers.forEach(container => {
        (container as HTMLElement).style.opacity = '0';
    });

    animatePair(0);
}
 PageOpen(route: string): void {
    const scrollPosition = window.scrollY;
    sessionStorage.setItem('scrollPosition', scrollPosition.toString());
    this.router.navigate([route]);
  }


}