import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactComponent } from './contact.component';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { HttpClientModule, /* other http imports */ } from "@angular/common/http";
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    imports: [CommonModule,FormsModule,ReactiveFormsModule,HttpClientModule,ToastrModule.forRoot()],
    declarations: [ContactComponent],
    exports: [ContactComponent]
})
export class ContactModule { }