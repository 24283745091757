import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { ContactService } from '../../contact/contact.service';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  public currentTeam : any;
  public is_open : any;
  constructor(public contactService: ContactService,private title: Title, private meta: Meta) { }

  ngOnInit() {
    window.scroll(0,0);
    this.title.setTitle('Company');
    this.is_open=false;
    this.currentTeam=this.data[0];
  }
  
  public sidebaropen(){
    this.is_open=!this.is_open;
  }
  public data= [{
    name:'Prasad Dasika',
    position:'CEO',
    image:'assets/img/team/1.jpg',
    about:'<li> Believes we live in exponential times, and that technology can be a force for good.</li><li> Developed breakthrough products and built businesses leading high performance teams. Holds 5 US patents and several publications in scientific journals.</li><li> Global leadership roles at CIENA, HFCL, Tech Mahindra & Tata Communications.</li><li> A below average piano player, with passable cooking skills. Won’t let peer assessments dissuade him from indulging excessively in these passions </li>',
    patents:'5',
    publications:'10+',
    statement:'We shall put in founder statement of founding principle or maybe a vision statement for the company here.',
},
{
  name:'Jagan Buddharaju',
  position:'CTO',
  image:'assets/img/team/4.png',
  about:'<li> Coding may not lead to world peace, but certainly gives him peace of mind.</li><li> Way more comfortable with Python than ppt. Built one of the most advanced telematic systems in the world, and a serial entrepreneur.</li><li> Senior roles at IBM, Compuware, and Deccan Fine Chemicals </li>',
  patents:'5',
  publications:'10+',
  statement:'We shall put in founder statement of founding principle or maybe a vision statement for the company here.',
},
{
  name:'Jagrithi Raju',
  position:'Head of Development',
  image:'assets/img/team/2.jpg',
  about:'<li> When she’s not hiking in the Himalayas or training for a marathon, you will find her building large-scale enterprise software solutions.</li><li> Core product development in Sun Microsystems and Blue Yonder, built highly critical defense and transport systems deployed across the world.</li><li> Building teams and creating products gives her the same rush as the mountainous adventures she seeks </li><li> Believes technology with the right people will lead us to a better tomorrow.</li>',
  patents:'5',
  publications:'10+',
  statement:'We shall put in founder statement of founding principle or maybe a vision statement for the company here.',
}
  ]

  config: SwiperOptions = {
    loop: true,
    autoplay:false,

    autoHeight: true,
    allowTouchMove: true,
    spaceBetween: 1,
    slidesPerView: 3,
    centeredSlides: true,
    roundLengths: true,
    navigation: {
      nextEl: '.team-swiper-button-next',
      prevEl: '.team-swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1
      },

      1200: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 3,
      }
    },
    on: {
      slideChange: (activeIndex) => {
        this.currentTeam=this.data[activeIndex.realIndex]

      },
      slideChangeTransitionEnd: () => {
        console.log('slideChange Event');
      }
    }
    
  };

}
