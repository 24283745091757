import { Component, OnInit,Input } from '@angular/core';
import { ContactService } from '../../contact/contact.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  
  
  constructor(public contactService: ContactService) { }

  ngOnInit() {
   
  }


  

}
