import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ContactModule } from './contact/contact.module';
import {APP_BASE_HREF} from '@angular/common';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { AboutComponent } from './pages/about/about.component';
import { ProductsComponent } from './pages/products/products.component';
import { CompanyComponent } from './pages/company/company.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { IndustriesComponent } from './pages/industries/industries.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HeadroomModule } from "@ctrl/ngx-headroom";
import { NgxStickySidebarModule } from '@smip/ngx-sticky-sidebar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModelService } from './services/model.service';

import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { HttpClientModule, /* other http imports */ } from "@angular/common/http";
import { ToastrModule } from 'ngx-toastr';

import { ParticlesModule } from 'angular-particle';
import { Abstract3dmodelComponent } from './components/abstract3dmodel/abstract3dmodel.component';
import { HashLocationStrategy,LocationStrategy } from '@angular/common';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ProductsComponent,
    CompanyComponent,
    ContactusComponent,
    Abstract3dmodelComponent,
    IndustriesComponent
    
    
  ],
  imports: [
    ContactModule,
    BrowserModule,
    AppRoutingModule,
    NgxUsefulSwiperModule,
    LottieModule.forRoot({ player: playerFactory }),
    BrowserAnimationsModule,
    HeadroomModule,
    NgxStickySidebarModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ParticlesModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
