
import { Component, AfterViewInit, ElementRef, Renderer2, ViewChild, NgZone, OnInit } from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { InstancedMesh } from 'three/src/objects/InstancedMesh';
@Component({
  selector: 'app-abstract3dmodel',
  templateUrl: './abstract3dmodel.component.html',
  styleUrls: ['./abstract3dmodel.component.css']
})
export class Abstract3dmodelComponent implements OnInit {

  private scene: any;
  private mouse: any;
   private camera: any;
  private renderer: any;
  private cube: any;
  trackGeometry !: THREE.BufferGeometry;
  trackMaterial !:  THREE.MeshBasicMaterial;
  trackInstances !: InstancedMesh;
  @ViewChild('rendererContainer', {static:true}) rendererContainer !: ElementRef;
  
  model !: THREE.Group; 
  constructor(private el: ElementRef, private renderer2: Renderer2,private ngZone: NgZone) {}

  ngAfterViewInit() {
    
    this.init();
    this.loadModel();
    this.addOrbitControls(); 
    this.animate();
    this.addLights();
    
  }
  init() {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setClearColor(0xffffff);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    // this.camera.position.set(0, 0, 5);
this.camera.position.z= 3
    this.mouse = new THREE.Vector2();
   const pixelRatio = window.devicePixelRatio || 1;
    this.renderer.setPixelRatio(pixelRatio);
  }
  
loadModel() {
    const loader = new GLTFLoader();
    const modelPath = 'assets/img/3dshape13.glb';
   loader.load(modelPath, (gltf) => {
      gltf.scene.traverse((node: any) => {
        if (node.isMesh) {
         const material = new THREE.MeshStandardMaterial({
            color: 0xe63939,
            emissive: node.material.emissive, 
            roughness: node.material.roughness, 
            metalness: node.material.metalness, 
            map: node.material.map,
           
          });
          node.material = material;
        }
      });
  const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
       let modelPositionX;
      if (screenWidth <= 768) { 
        modelPositionX = 0
        gltf.scene.scale.set(0.9, 0.8, 0.9);
      } else {
        gltf.scene.scale.set(1, 1, 1);
        modelPositionX = -2.8; 
      }
  
   gltf.scene.position.x = modelPositionX;
   this.model = gltf.scene;
   this.scene.add(this.model);
    });
  }
    animate() {
    let rotateY = true;
    let rotateZ = false;
   this.ngZone.runOutsideAngular(() => {
      const animateFunc = () => {
        requestAnimationFrame(animateFunc);
  
        if (this.model) {
         
          if (rotateY) {
            this.model.rotation.y += 0.01;
               if (this.model.rotation.y >= Math.PI * 2) {
              this.model.rotation.y -= Math.PI * 2; 
              rotateY = false;
              rotateZ = true; 
            }
          }
   if (rotateZ) {
            this.model.rotation.x += 0.01; 
   if (this.model.rotation.x >= 2*Math.PI) {
              this.model.rotation.x -= 2*Math.PI; 
              rotateY = true; 
              rotateZ = false; 
            }
          }
        }
         this.renderer.render(this.scene, this.camera);
      };
      animateFunc();
    });
  }
  
addOrbitControls() {
    const controls = new OrbitControls(this.camera, this.renderer.domElement);
  controls.autoRotate = false;
  controls.enableDamping = false;
    controls.dampingFactor = 0.25;
   controls.enableZoom = false;
   controls.enableZoom = false;
 controls.enabled = false;
controls.enableRotate = false;
  }
  addLights() {
   
    const ambientLight = new THREE.AmbientLight(0xffffff, 1.1);
    this.scene.add(ambientLight);
    const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x999999);
    this.scene.add(hemisphereLight);
   const directionalLight = new THREE.DirectionalLight(0xffffff, 1.1);
    directionalLight.position.set(1, 1, 1).normalize();
    this.scene.add(directionalLight);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
}

 ngOnInit() {
  }

}
