import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router,ActivatedRoute,Params } from '@angular/router';

import { ContactService } from '../../contact/contact.service';

import Splitting from "splitting";
import ScrollOut from "scroll-out";
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;
  closeResult = '';
  public is_open:any;
  public page:any;
  public Leftcard:any;
  public Rightcard:any;
  toggleVideo() {
   
    this.isPlay=true;
    this.videoplayer.nativeElement.play();
  }

  playVideo() {
    this.isPlay=true;
    this.videoplayer.nativeElement.play();
  }
  
  constructor(private route: ActivatedRoute,public contactService: ContactService,private title: Title, private meta: Meta) {
    this.route.params.subscribe((params: Params) =>
     this.page = this.data[params['id']] 
     
     );
    this.Leftcard = this.page.cards.slice(0,3);
     this.Rightcard = this.page.cards.slice(3);
   }

  ngOnInit() {
    window.scroll(0,0);
    this.title.setTitle(this.page.seotitle);
    Splitting();
   ScrollOut({
      targets: '[data-splitting]',
      scrollingElement: "section"
    });
   ScrollOut({
    onShown: function(el,ctx, scrollingElement) {
      console.log(ctx.elementHeight);
      console.log(scrollingElement);
      // use the web animation API
        el.classList.remove('animated');
        void el.offsetWidth;
        
        el.classList.add('animated');
      
    },
    onHidden: function(el,ctx, scrollingElement) {
      // hide the element initially
      el.classList.remove('animated');
     
    } });
    this.is_open=false;
  }

  
  public popupopen ():void{
    this.is_open=true;
  }
  public closeopen ():void{
    this.videoplayer.nativeElement.pause();
    this.is_open=false;
  }
  

  public data= {
    smartent:{
              title:'<span>Smart<b class="last">Ent</b></span>',
              seotitle:'SmartEnt',
              header1:'Structured analytics platform',
              header2:'SmartEnt gives you the tools you need to plan, track & efficiently manage processes across your shop floor, whether it is Industrial IoT or production planning',
              image:'assets/img/tabs/smartent.webp',
              video:'',
              help:{
                para1:'Managing production in continuous process industries can get complex',
                para2:'Production planning, monitoring of live process parameters, regulatory compliances, shared resources collaboration, managing inventory and finances is an exercise in multi objective optimization',
                para3:'',
                yello:'SmartEnt helps you monitor millions of parameters and make optimal decisions.'
              },
              cards:[
                {
                title:'Digitize',
                image:'assets/img/digitize.png',
                color:'yellowbg',
                para:'Digitize your entire production planning process'
              },
              {
                title:'Maximize',
                image:'assets/img/maximize.png',
                color:'greenbg',
                para:'Maximize the utilization of your biggest investment, the production plant'
              },
              {
                title:'Visualize',
                image:'assets/img/visualize.png',
                color:'bluebg',
                para:'Intuitive and visual production plans, optimize for revenue, throughput, or delivery'
              },
              {
                title:'Monitor & modify',
                image:'assets/img/visualize.png',
                color:'bluebg',
                para:'Track & optimize millions of process parameters to ensure high quality output'
              },
              
              {
                title:'Always in sync',
                image:'assets/img/utilize.png',
                color:'redbg',
                para:'Procurement plan always in lock step with production plan and demand'
              },
              {
                title:'Reduce',
                image:'assets/img/digitize.png',
                color:'yellowbg',
                para:'Reduce inventory build up and effectively meet regulatory requirements'
              },
            ],
            works:"Digging more deeper, are we? Great, here's how we know it works",
            workcards:[
              {
                content:'Ingests revenue target, product mix, production target, delivery timelines, or a combination',
                type:'Productworkslay1'
              },
              {
                content:'Uses complex multi-constraint optimization models and delivers options for desired outcomes',
                type:'Productworkslay2'
              },
              {
                content:'Leverages existing ERP systems, and provides operational intelligence',
                type:'Productworks-box-no'
              }
            ],
            footer:{
              title:'Production planning, <span class="first">elevated.</span>',
              content:'Click on the red button below when you are ready to evolve the way you manage & plan things on the shop floor.'
            }
          },
     lectus:{
      title:'<span>lectus</span>',
      seotitle:'lectus',
      header1:'Natural Language Understanding engine,',
      header2:'“lectus” helps you extract insights from your documents, emails, invoices, contracts, or any other type of document. Large Language models, Small Language models, Generative AI, we’ll leverage the most appropriate technology to solve your problems',
      image:'',
      video:'assets/video/KYNEA.mp4',
      help:{
        para1:'Whether it is deep understanding of large documents, or extraction of  key information from smaller documents such as contracts or invoices, or emails.',
        para2:' Our proven algorithms, extract key information, understand content and context and help  drive actions in your enterprise workflow to automate critical business processes such as a customer care, invoice processing, or document parsing.',
        para3:'',
        yello:'“lectus”, enables you to automate enterprise workflows associated with decision making based on document understanding.'
      },
      cards:[
        {
          title:'Automate',
          image:'assets/img/digitize.png',
          color:'yellowbg',
          para:'Invoice processing, Claims processing, Customer communications'
        },
        {
          title:'Reduce',
          image:'assets/img/utilize.png',
          color:'redbg',
          para:'Identify delays, inefficient processes, and reduce problem resolution time'
        },
        {
          title:'Uncover',
          image:'assets/img/visualize.png',
          color:'bluebg',
          para:'Cognitive search across enterprise data'
        },
        {
          title:'Reimagine',
          image:'assets/img/digitize.png',
          color:'bluebg',
          para:'Reduce business friction and implement a more profitable pre and post sales journey '
        },
      {
        title:'Visualize',
        image:'assets/img/visualize.png',
        color:'bluebg',
        para:"Map the real customer's journey & plan problem resolution"
      },
      {
        title:'Segmentation',
        image:'assets/img/maximize.png',
        color:'greenbg',
        para:'Separate clauses based in topic and importance'
      },
      
      
      
      
    ],
    workcards:[
      {
        content:'1000s of high accuracy pre-trained models that understand the “micro” and “macro” in conversations',
        type:'Productworkslay1'
      },
      {
        content:'Uses an array of  algorithms  to interpret text, voice, and documents',
        type:'Productworkslay2'
      },
      {
        content:'Leverages proprietary zipLearn™ algorithm that drastically reduces training time ',
        type:'Productworks-box-no'
      }
    ],
    footer:{
      title:'Customer insights, <span class="first">simplified.</span>',
      content:'Talk to us today to leverage deeper insights & supercharge you organization.'
    }
          },
     lisa:{
      title:'<span>LISA</span>',
      seotitle:'Lisa',
      header1:'Deep bot with conversational intelligence',
      header2:'LISA enables complex, contextual, and nonlinear conversations, thanks to the power of AI',
      image:'assets/img/tabs/LISA.webp',
      video:'',
      help:{
        para1:'B2B pre sales can get complex. Providing the right technical advice to your sophisticated customers is heavily people dependent ',
        para2:'Ensuring that your sales team conveys the right message about your product, is extremely difficult to manage',
        para3:'And when you have partner solution-team in the loop, how do you ensure effective customer conversations? ',
        yello:'LISA is your first step in an AI enabled sales force'
      },
      cards:[
        {
        title:'Qualify deals',
        image:'assets/img/digitize.png',
        color:'yellowbg',
        para:'Enables sales reps, Digital sales, and marketing to qualify deals thoroughly'
      },
      {
        title:'Fast track',
        image:'assets/img/maximize.png',
        color:'greenbg',
        para:'Supercharge partner revenue generation with 24 X 7 deep technical support'
      },
      {
        title:'Streamline',
        image:'assets/img/utilize.png',
        color:'redbg',
        para:'Gathering feedback on your product from your sales team is faster & easier'
      },
     
      {
        title:'Be smarter',
        image:'assets/img/maximize.png',
        color:'greenbg',
        para:'Provide deep technical & contextual answers'
      },
      {
        title:'Get the latest info',
        image:'assets/img/visualize.png',
        color:'bluebg',
        para:'Single source of truth about your products for your sales teams'
      },
      {
        title:'Resolve',
        image:'assets/img/digitize.png',
        color:'yellowbg',
        para:'Post sales, help your customers troubleshoot complex issues quickly'
      },
    ],
    workcards:[
      {
        content:'Leverages the natural language understanding capabilities of “lectus”',
        type:'Productworkslay1'
      },
      {
        content:'Deep learning to understand context and provide nonlinear conversation experience',
        type:'Productworkslay2'
      },
      {
        content:'Yet, personable enough for chit chat',
        type:'Productworks-box-no'
      }
    ],
    footer:{
      title:'Customer conversations, <span class="first">simplified.</span>',
      content:'Talk to us today, and supercharge your sales team'
    }
          },
          Tsquare:{
        title:'<img  height="100px" src="assets/img/Tsquare.png"><span>T- Square</span>',
              seotitle:'T- Square',
              header1:'Engineering drawing analysis',
              header2:'T-Square employs patent pending AI algorithms to analyze intricate engineering drawings to provide accurate cost estimates, detect revision differences for precise project cost evaluation',
              image:'assets/img/tabs/CAD.png',
              video:'',
              help:{
                para1:'We recognize the challenges inherent in manual assessment of engineering drawings to estimate project costs and manage revisions effectively. ',
                para2:'People intensive methods often lead to inaccuracies, time inefficiencies. T-Square addresses these pain points by leveraging our patent-pending techniques in AI to streamline the process',
                para3:'',
                yello:'When seemingly small changes in drawings can move project costs by hundred of thousands of dollars or more, T-Square is your answer!'
              },
              cards:[
                {
                  title:'Accurate project costs',
                  image:'assets/img/utilize.png',
                  color:'greenbg',
                  para:'Repeatable and accurate project cost estimates based on drawing analysis'
                },
                {
                  title:'Monitor & track',
                  image:'assets/img/visualize.png',
                  color:'redbg',
                  para:'Track all changes, from design to as-built to understand project changes & cost evolution'
                },
                {
                  title:'Complex  calculations',
                  image:'assets/img/utilize.png',
                  color:'redbg',
                  para:' Specification compliance, Weld volume assessment, Paint requirements'
                },
                
             
              
              {
                title:'Spatial models',
                image:'assets/img/digitize.png',
                color:'yellowbg',
                para:'Explore projects in 3D environment'
              },
              {
                title:'Saves time & effort',
                image:'assets/img/maximize.png',
                color:'greenbg',
                para:'Complete critical activities within a matter of seconds or minutes'
              },
              {
                title:'Always-in-sync',
                image:'assets/img/visualize.png',
                color:'bluebg',
                para:'Procurement plan always in lock step with design'
              },
              
             
            ],
            workcards:[
              {
                content:'Ingests a wide variety of drawing formats',
                type:'Productworkslay1'
              },
              {
                content:'Cognitively extract all relevant information within drawings ',
                type:'Productworkslay2'
              },
              {
                content:'Built in engineering rules to transform drawing information into  business parameters',
                type:'Productworks-box-no'
              }
            ],
            footer:{
              title:'Engineering drawings, <span class="first"> cognitively analyzed</span>',
              content:'Talk to us today, and drive cognitive automation across your organization'
            }
        },
        zoom:{
          title:'<span>ZOOM</span>',
          seotitle:'Zoom',
                header1:'Lean, flexible workflow engine',
                header2:'Leverage your existing systems and orchestrate complex workflows that go beyond mimicking agent actions',
                image:'assets/img/tabs/Zoom.webp',
                video:'',
                help:{
                  para1:'Integrating cognitive and complex decisions into real time actions should not necessitate expensive systems',
                  para2:'',
                  para3:'',
                  yello:'ZOOM is lean, flexible, and  orchestrates intelligent actions across your enterprise'
                },
                cards:[
                  {
                    title:'Scalable',
                    image:'assets/img/utilize.png',
                    color:'redbg',
                    para:'Highly resilient and processes millions of transactions'
                  },
                  {
                  title:'Compatible',
                  image:'assets/img/digitize.png',
                  color:'yellowbg',
                  para:'Interfaces with your existing systems, & applications seamlessly'
                },
               
                {
                  title:'Track',
                  image:'assets/img/visualize.png',
                  color:'bluebg',
                  para:'Process reporting with full audit trail across all transactions'
                },
                {
                  title:'Flexible',
                  image:'assets/img/visualize.png',
                  color:'bluebg',
                  para:'Designed to handle complex workflows'
                },
                {
                  title:'Integrated',
                  image:'assets/img/maximize.png',
                  color:'greenbg',
                  para:'Leverages lectus & SmartEnt and drives actions'
                },
                {
                  title:'Drives action',
                  image:'assets/img/utilize.png',
                  color:'redbg',
                  para:'Converts real time cognitive decisions into actions'
                }
              ],
              workcards:[
                {
                  content:'Smart, scalable, low code platform',
                  type:'Productworkslay1'
                },
                {
                  content:'Fully redundant architecture, handles millions of transactions',
                  type:'Productworkslay2'
                },
                {
                  content:'Cloud or on-prem deployment',
                  type:'Productworks-box-no'
                }
              ],
              footer:{
                title:'Enterprise actions, <span class="first">orchestrated.</span>',
                content:'Talk to us today, and drive cognitive automation across your organization'
              }
          }
  }


}
