import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ProductsComponent } from './pages/products/products.component';
import { CompanyComponent } from './pages/company/company.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { IndustriesComponent } from './pages/industries/industries.component';

const routes: Routes = [
  { path: '', component: HomeComponent,pathMatch: 'full' },
  { path: 'product/:id', component: ProductsComponent,pathMatch: 'full' },
  
  /*{ path: '', component: ProductsComponent },*/
  { path: 'aboutus', component: AboutComponent,pathMatch: 'full' },
  { path: 'company', component: CompanyComponent,pathMatch: 'full' },
  { path: 'contactus', component: ContactusComponent,pathMatch: 'full' },
  { path: 'industry/:id', component: IndustriesComponent,pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
