import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContactService {
    public contact = new BehaviorSubject<any>('');
    notifyObservable$ = this.contact.asObservable();
    public ContactForm(data: any) {
        if (data) {
            this.contact.next(data);
        }
    }

   
}