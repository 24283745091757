import { Component, OnInit } from '@angular/core';
import { ContactService } from '../../contact/contact.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public Menuicon : any;
  public Menuflags:any;
  public url:any;
  constructor(public contactService: ContactService,private router: Router) { }

  ngOnInit() {
    this.Menuicon='bi-list';
    this.Menuflags=false;
    this.url= environment.baseurl;
    console.log(this.url)
  }


 public menuopen() : void {
  
  this.Menuflags= !this.Menuflags;
  
 }

 public PageOpen(links) : void {
  this.Menuicon='bi-list';
  this.Menuflags=false;
  this.router.navigate([links]);
 }

 public PageOpen_url(links) : void {
  this.Menuicon='bi-list';
  this.Menuflags=false;
  this.router.navigate(this.url+links);
 }

}
