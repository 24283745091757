import { Component, OnInit,ViewChild,Input } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'ngx-useful-swiper';
import { Router,ActivatedRoute,Params } from '@angular/router';
import { ContactService } from '../../contact/contact.service';

import { HostListener } from '@angular/core';
import Splitting from "splitting";
import ScrollOut from "scroll-out";
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.css']
 
})
export class IndustriesComponent implements OnInit {
  @ViewChild('usefulSwiper', { static: false }) usefulSwiper: SwiperComponent;
  public tabdata : any;
  public tabdatalabel : any;
  public tabactive : any;
  public animationcss : any;
  public type:any;
  public page:any;
  animState = false;
  constructor(private route: ActivatedRoute,public contactService: ContactService,private title: Title, private meta: Meta) {
    this.route.params.subscribe((params: Params) =>
     
     this.page=this.data[params['id']]

     );

     
     
   }
  
  ngOnInit() {
    window.scroll(0,0);
    let id = this.route.snapshot.paramMap.get('id');
    this.page=this.data[id];
    this.tabdata=this.page.slide[0];
    
    this.title.setTitle(this.page.seotitle);
    this.tabdatalabel
    this.tabactive='PLAN';
    this.animationcss=false
    Splitting();
    ScrollOut({
       targets: '[data-splitting]',
       scrollingElement: ".scrollsection"
     
     });
    ScrollOut({
     onShown: function(el) {
       // use the web animation API
         el.classList.remove('animated');
         void el.offsetWidth;
         el.classList.add('animated');
       
     },
     onHidden: function(el) {
       // hide the element initially
       el.classList.remove('animated');
      
     } });
  }

 

  public Tabchange(index):void {
    this.animationcss=false
    this.tabdata=this.page.slide[index];
    this.tabactive=this.page.slide[index].title;
    this.animationcss=true
  }
  
  
  
  public data= {
  'chemical-pharmaceuticals':{
      title:'<span class="first">CHEM</span>ICAL <br>& PHARMACEUTICAL ',
      seotitle:'CHEMICAL PHARMACEUTICAL',
      image:'assets/img/tabs/chem_main.webp',
      content:'Production planning, inventory control and maintenance of heavy machinery in process intensive industries like Manufacturing, Chemical, Pharma, Paper, etc., is complex, and manual.   ERP systems have built-in material planning features, however, deploying these involves extensive customization and they do not provide intuitive and easy-to-use interfaces',
      slide:[{
          title:'PLAN',
          header:'PLAN',
          image:'assets/img/values-3.png',
          float_label:'Production planning-as-a-Service',
          right_side_h6:'Production planning is currently hidden in complex excel sheets and riddled with capacity leakage',
          right_side_p1:'Welcome to the next generation of production planning with Visual production plans, complete accounting of equipment utilization and a inventory that is in sync with production plans, and regulatory requirements.',
          right_side_p2:'Arrest capacity leakages in your plant and unlock additional revenue potential',
      },
      {
        title:'MAINTAIN',
        header:'Monitor',
        image:'assets/img/values-2.png',
        float_label:'Process control intelligence',
        right_side_h6:'Precise process control is key to quality compliant output in process industries',
        right_side_p1:'Our data analytics platform, SmartEnt removes the complexity of yield optimization and process control. It compares actual RM consumption with norms to identify process gaps, provides sensitivity analysis on RM quality parameters, and identifies batch cycle deviation from golden bands in real time to provide process operators with insight to modify the process and realize higher yield',
        right_side_p2:'',
    },
    {
      title:'CONTROL',
      header:'Maintain',
      image:'assets/img/tabs/chem.webp',
      float_label:'Maintenance of sensitive machinery',
      right_side_h6:'The biggest investment in a plant is heavy machinery, however, the operators may not highly qualified.',
      right_side_p1:'Deep Bot LISA provides step by step guidelines to operators on break fix, & maintenance to ensure  equipment is serviced in a manufacturer approved process.  With the ability to capture observations at every step, this provides experts insight into identifying signs of break down, and complete auditable logs that can be shared with the manufacturer during warranty discussions to reduce cost and avoid surprise breakdowns',
      right_side_p2:'',
    }]
  },
  'banking':{
    title:'Banking',
    seotitle:'Banking',
    image:'assets/img/tabs/banking.webp',
    content:'Banking & financial services industry relies on a synthesis of information from a wide variety of sources, mostly unstructured data We help our banks better understand their customers, and generate profitable growth',
    slide:[{
        title:'PLAN',
        header:'Engage',
        image:'assets/img/tabs/banking/engage.webp',
        float_label:'Personalized customer engagement',
        right_side_h6:'Contextual & nonlinear conversations is key to effective customer engagement',
        right_side_p1:'Our Deep Bot LISA is trained to handle non linear conversations, with solid understanding of customer’s intent, and context preservation to ensure customers  are provided not just with plain information, but a deeper view of products and services along with avenues to introduce newer services to customers',
        right_side_p2:'',
    },
    {
      title:'MAINTAIN',
      header:'Recommend',
      image:'assets/img/tabs/banking/recommand.webp',
      float_label:'Knowing customers is more than KYC',
      right_side_h6:'Key problems banks face is deeper product penetration.',
      right_side_p2:'',
      right_side_p1:'Our structured analytics platform SmartEnt, allows banks to explore customer demographics, characteristics, payment transfers, point of sale data to discover customer micro segments that enables a bank to surgically target customers with relevant services and gain deeper product penetration',
  },
  {
    title:'CONTROL',
    header:'Review',
    image:'assets/img/tabs/banking/review.webp',
    float_label:'Contracts review',
    right_side_h6:'Modern finance is impossible without contracts, and they are voluminous, complicated, and extremely time consuming to review',
    right_side_p1:'Our NLP platform lectus is trained to recognize key attributes of documents.  It extracts key information deemed important by the bank’s legal team, and summarizes the contents saving countless hours for the legal team',
    right_side_p2:'',
  }]
},

'enterprise-telecom':{
  title:'<span class="first">Enterprise</span> <br>Telecom ',
  seotitle:'Enterprise Telecom',
  image:'assets/img/tabs/Telco_Main.webp',
  content:'Enterprise telcos are a treasure trove of data, structured and unstructured.  A data scientist’s dream, but can be frustrating.  Our unique suite of solutions drive velocity in enterprise telco pre-sales and post-sales.  We bring together natural language understanding, and seamlessly meld it with complex structured data analysis to drive  business velocity and profitable outcomes',
  slide:[{
      title:'PLAN',
      header:'Engage',
      image:'assets/img/tabs/telecom/1.webp',
      float_label:'Effective presales engagement, AI enabled sales force',
      right_side_h6:'',
      right_side_p1:'Our deep Bot LISA is designed to give your sales & partner teams the AI edge.  It provides deep, and relevant technical information to customers, partners, solution engineers, and sales to ensure sales & channel partners qualify the deal comprehensively, solution engineers provide accurate and in depth information about solutions thereby increasing deal velocity',
      right_side_p2:'',
  },
  {
    title:'MAINTAIN',
    header:'Sell',
    image:'assets/img/tabs/telecom/2.webp',
    float_label:'Drive product penetration',
    right_side_h6:'Telcos have a large global portfolio of solutions, however product penetration tends to be low',
    right_side_p1:'Our data analytics platform, analyzes customer characteristics such as size, industry, locations, and determines buying patterns',
    right_side_p2:'Based on this it makes recommendations to sales teams on likely products that a customer is likely to be interested in thereby providing an executable path to increasing product penetration',
},
{
  title:'CONTROL',
  header:'Deliver',
  image:'assets/img/tabs/telecom/3.webp',
  float_label:'Service center transformation',
  right_side_h6:'Telco Service centers are heavy in manpower due to the complex nature of products they support',
  right_side_p1:'Our suite of solutions drive the entire ticket life cycle.  It analyzes logs to identify root cause, provide detailed instructions on customer trouble shooting and allocates tickets to agents where needed.  It drives cognitive interactions with access providers, and responds to customers with minimal or no agent involvement',
  right_side_p2:'',
}]
},
'engineering-construction':{
  title:'<span class="first">Engineering, Procurement</span> <br>& Construction ',
  seotitle:'Engineering, Procurement & Construction',
  image:'assets/img/tabs/construction.webp',
  content:'Managing large scale construction projects is a complex task,  our solutions bring the power of analytics, machine learning and AI to help your teams take the sweat out of complex decision making, and deliver projects on time',
  slide:[{
      title:'PLAN',
      header:'Plan',
      image:'assets/img/tabs/epc/plan.webp',
      float_label:'Material planning-as-a-service',
      right_side_h6:'Right material, at the right location, at the right time is 50% of project success',
      right_side_p1:'Our SmartEnt analytics platform takes into account material usage, compares it against construction norms, takes into account material required in various stages of delivery and drives the procurement.',
      right_side_p2:'Arrest loss of site productivity',
  },
  {
    title:'MAINTAIN',
    header:'Comply',
    image:'assets/img/tabs/epc/comply.webp',
    float_label:'Compliance-as-a-service',
    right_side_h6:'Large projects require hundreds of service providers, and workers involved in physical activities.  Lack of compliance delays vendor payments.',
    right_side_p1:'Compliance-as-a-service powered by lectus and ZOOM, reads complex documents from your service providers, analyzes them, and determines vendor compliance with regulations',
    right_side_p2:'Saves hundreds of hours of manual cross verification, provides fast and accurate compliance assessments',
},
{
  title:'CONTROL',
  header:'Monitor',
  image:'assets/img/tabs/epc/quality.webp',
  float_label:'Construction quality monitoring',
  right_side_h6:'Using AI to monitor construction quality is an emerging area',
  right_side_p1:'Our platform is trained on millions of images and provides instant feedback on construction quality to identify defects',
  right_side_p2:'Our zipLearn algorithm ensures that your quality monitoring system is up and running in no time',
}]
},
  }


    public config: SwiperOptions = {
      // Optional parameters
     
    direction: 'vertical',
    loop: true,
    mousewheel:false,
    pagination: false,
    keyboard: true,
    parallax:true,
    speed:100,
    autoplay:false,
    /*autoplay: {
      delay: 2000,
      disableOnInteraction: true
  },*/
  loopFillGroupWithBlank: false,
    effect:'fade',
    slidesPerView:1,
    grabCursor:true,
    
    
    on: {
      slideChange: (activeIndex) => {
        this.tabactive=this.page.slide[activeIndex.realIndex].title;
        //this.Currentno=activeIndex.realIndex + 1
        //this.Totalno=activeIndex.slides.length;
      },
      slideChangeTransitionEnd: (activeIndex) => {
        //this.tabdata=this.data[activeIndex.realIndex];
        
      }
    }
      
    };

   public slideToThis(index) {
      this.tabactive=this.page.slide[index-1].title;
      this.usefulSwiper.swiper.slideTo(index,10,false);
    }
    
   
}


