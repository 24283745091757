import { Component, OnInit, OnDestroy, Input, HostListener,ViewChild,ElementRef, Inject,Renderer2} from '@angular/core';
import { ClickOutsideModule } from 'ng-click-outside';

import { ContactService } from './contact.service';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup , Validators,FormArray,FormControl  } from '@angular/forms';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({ selector: 'contact', templateUrl: 'contact.component.html' })
export class ContactComponent implements OnInit, OnDestroy {

  contactForm: FormGroup;
    @ViewChild('modal',{static:false}) modal: ElementRef;
    public is_submit :any;
    public is_open :any;
    public is_form :any;
    public is_success :any;
    public text: String;
    constructor(private toastr: ToastrService,private contactService: ContactService,private renderer: Renderer2,private _formBuilder: FormBuilder,
        @Inject(DOCUMENT) private document: Document,private eRef: ElementRef,private http: HttpClient) {
        
     
   
     }

     

    ngOnInit() {
      this.is_submit='SEND';
        this.is_open=false;
        this.is_form=true;
        this.is_success=false;
        this.contactService.notifyObservable$.subscribe(res => {
            if(res.open){
                this.is_open=true
                this.document.body.classList.add('model_open');
                this.document.body.classList.remove('model_open_not');
            }
      })
      this.contactForm = this._formBuilder.group({
        name   : ['', [Validators.required]],
        email:['', [Validators.required,Validators.email]],
        subject:['', Validators.required],
        message:['', Validators.required]
    });
    }

    public closeopen (){
        this.is_open=false;
        this.is_form=true;
        this.is_success=false;
        this.is_submit='SEND';
        this.document.body.classList.add('model_open');
        this.document.body.classList.remove('model_open_not');
      }

      public closeopen1 (){
        this.is_open=false;
        this.is_form=true;
        this.is_success=false;
        this.is_submit='SEND';
        this.contactForm.reset();
        this.document.body.classList.add('model_open');
        this.document.body.classList.remove('model_open_not');
      }

      

    ngOnDestroy() {}
    
    onClickedOutside(e: Event) {
        console.log('Clicked outside:', e);
      }

      outsideClick() {
        this.closeopen();
    }

    async onClickSubmit(): Promise<void> { 
      if(this.contactForm.valid) {
        this.is_submit='LOADING..';
        /* window.setTimeout(() => {
          this.contactForm.reset();

          this.is_form=false
          this.is_success=true
          this.is_submit='SEND';
        }, 3000); */

        // environment.contacturl+'server/send.php', this.contactForm.value
        // let url = 'http://localhost:8909/sendEmail?name='+ this.contactForm.value.name +'&email='+ this.contactForm.value.email +'&message='+ this.contactForm.value.message +'&particulars='+ this.contactForm.value.subject
        
        /* this.http.post(environment.contacturl, this.contactForm.value).subscribe(
          (res: any) => {
            if(res.status){
              this.contactForm.reset();

             this.is_form=false
             this.is_success=true
             this.is_submit='SEND';
             
            }else{
              this.toastr.error(res.message, 'Error!');
              this.is_submit='SEND';
            }
          },(error: any) => {}
        ) */

        console.log(environment.contacturl, this.contactForm.value);
        let params: string = "?name=" + this.contactForm.value.name
        params += "&email="+ this.contactForm.value.email
        params += "&subject="+ this.contactForm.value.subject
        params += "&message="+ this.contactForm.value.message
        this.http.post(environment.contacturl+params, {}).subscribe(
          (res: any) => {
            console.log(res);
            //TODO change this
            // if(res.status){
              this.contactForm.reset();

             this.is_form=false
             this.is_success=true
             this.is_submit='SEND';
             
            // }else{
            //   this.toastr.error(res.message, 'Error!');
            //   this.is_submit='SEND';
            // }
          },(error: any) => {
            //TODO change this
            this.contactForm.reset();

             this.is_form=false
             this.is_success=true
             this.is_submit='SEND';
          }
        )
      } else {
        const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(this.contactForm.value.name==''){
          this.toastr.error('Please enter name', 'Error!');
        }
        if(this.contactForm.value.email==''){
          this.toastr.error('Please enter email', 'Error!');
        } else if(!regularExpression.test(this.contactForm.value.email.toLowerCase())) {
          this.toastr.error('Please enter valid email', 'Error!');
        }
        if(this.contactForm.value.subject==''){
          this.toastr.error('Please enter any other particulars', 'Error!');
        }
        if(this.contactForm.value.message==''){
          this.toastr.error('Please enter message', 'Error!');
        }
      }
      
    }
    
}